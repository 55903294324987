.activity-group-header .basic-date-picker .MuiFormControl-root,
.activity-group-header-select{
    border-top: 1px solid #ececec;
    background-color: #ffffff !important;
    box-shadow: none;
    border-radius: 10px;
    width: 12.5rem !important;
}
.activity-group-header .basic-date-picker label,
.activity-group-header .basic-date-picker input,
.activity-group-header-select label,
.MuiSelect-select{
    font-size: 16px !important;
    font-weight: bold !important;
    color: #333132 !important;
}
.activity-group-header-select svg{
    color: #333132 !important;
}
.activity-group-header .basic-date-picker label{ line-height: 1em !important; }
.activity-group-header .basic-date-picker input{ padding: 12.5px 14px !important; }

.activity-group-header .basic-date-picker fieldset,
.activity-group-header-select fieldset{
    border: none !important;
    box-shadow: none !important;
}