.organization-table.currency-table table thead tr th:first-of-type {
    padding-right: 1.5rem;
}

.organization-table.currency-table table tbody tr td:first-of-type {
    padding-right: 1.5rem;
}

.currency-table table thead tr p,
.currency-table table table tbody td div {
    min-width: max-content !important;
}