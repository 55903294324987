.organization-table table tbody tr > td > .certificate-success, 
.organization-table table tbody tr > td > .certificate-failed{
    padding: 7px 22px 8px !important;
    border-radius: 10px !important;
    color: white !important;
    width: 33px !important;
    max-width: 33px !important;
    text-align: center !important;
    margin-left: 0px !important;
    margin-right: auto !important;
}

.certificate-success{
    background-color: #62c08b !important;
}
.certificate-failed{
    background-color: #cc4d49 !important;
}

.organization-certificates-table table tbody tr td:nth-of-type(2){ font-weight: normal !important; }
.organization-table.organization-certificates-table table thead tr th:nth-of-type(2),
.organization-table.organization-certificates-table table tbody tr td:nth-of-type(2){
    padding-left: 80px !important;
}

.organization-table.organization-certificates-table table tbody tr td div.MuiPopper-root {
    left: 0px!important;
}

.organization-form .MuiBox-root label span.MuiFormControlLabel-label{
    font-size: 18px !important;
    font-weight: bold !important;
    color: #333132 !important;
}

.organization-form .organizationName input{
    font-weight: bold !important;
}