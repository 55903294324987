.participants-panel{
    padding: 20px 17px;    
    border-radius: 20px;
    background-color: #fbfbfb;
}

.participants-panel > h1{
    margin-bottom: 10px !important;
}

.participants-panel p{
    overflow-wrap: anywhere;
}