.user-pages-head button.primary{
    padding: 9px 41px;
}

.user-pages-head button.primary.primary-outlined{
    background: #fff !important;
    color: var(--primary-color) !important;
    border: 1px solid var(--primary-color) !important;
}

.user-pages-head button.primary.primary-text{
    border: none !important;
    color: var(--font-color) !important;
    background-color: transparent !important;    
}

.user-pages-head button.primary.primary-text-outline{
    border: 1px solid #ececec !important;
    color: var(--font-color) !important;
    background-color: transparent !important;    
}

.user-pages-head button.primary p{
    font-weight: 500 !important;
    font-size: 18px !important;
    text-transform: capitalize;
}


.user-pages-head .Search-users fieldset, .user-pages-head .Search-roles fieldset{
    border: none;
    outline: none;
    box-shadow: none;
}


.user-pages-head .MuiBreadcrumbs-root a{ text-decoration: none !important; }

.Search-roles > div, .Search-users > div{
    padding: 10px 15px 8px !important;
    border-radius: 10px !important;
    box-shadow: inset 0 1px 4px 0 rgba(0, 0, 0, 0.1) !important;
    background-color: #fff !important;
}

.Search-roles > div > input, .Search-users > div > input{
    padding: 0px !important;
}


.basic-date-picker {
    background-color: #ffffff; /* White background */
    border-radius: 10px; /* Rounded corners */
    display: inline-block;
  }
  
  .basic-date-picker .MuiFormControl-root {
    border-radius: 10px;
    width: 12.5rem;
    background-color: #ffffff; /* White background for the entire control */
    box-shadow: inset 0 1px 4px 0 rgba(0, 0, 0, 0.1) !important;

  }
  
  .basic-date-picker label,
  .basic-date-picker input {
    font-size: 16px; /* Adjust as needed */
    font-weight: bold;
    color: #3331328e;
    
  }
  
  .basic-date-picker label {
    line-height: 1em !important;
  }
  
  .basic-date-picker input {
    padding: 0px 10px !important; /* Adjust padding to fit the height */
    background-color: #ffffff; /* Ensure white background for the text input */
    border-radius: 5px; /* Slightly rounded corners for the input */
  }
  
  .basic-date-picker label.MuiFormLabel-root.MuiInputLabel-formControl.MuiInputLabel-shrink {
    display: none !important;
  }
  
  .basic-date-picker fieldset {
    border: none !important;
    box-shadow: none !important;
  }
  