.select{
  background: white !important;
  border-radius: var(--lg-radius) !important;
}

.select fieldset{
  border-radius: var(--lg-radius) !important;
  box-shadow: inset 0 1px 4px 0 rgb(0 0 0 / 10%) !important;
}
.searchable-select {
  box-shadow: inset 0 1px 4px 0 rgb(0 0 0 / 10%) !important;
}

.searchable-select div{
  border-radius: var(--lg-radius) !important;
  padding: 3.5px;
  cursor: pointer;
}


.css-t3ipsp-control{
  box-shadow: 0 0 0 1px #f6b119 !important;
  border-color: #f6b119 !important;
}

.searchable-select > div.css-t3ipsp-control:hover{  
  border-color: #f6b119 !important;
}


.searchable-select.select-error div{
  border-color: #d32f2f;
}
.searchable-select.select-error div.css-t3ipsp-control{
  box-shadow: 0 0 0 1px #d32f2f !important;
  border-color: #d32f2f !important;
}

.searchable-select.select-error > div.css-t3ipsp-control:hover{  
  border-color: #d32f2f !important;
}


.searchable-select:not(.select-error) > div:first-of-type:not(.css-t3ipsp-control):hover{
  border-color: rgba(0, 0, 0, 0.87) !important;
}

