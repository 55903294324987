.side-bar-container{
    background: #333132;
    padding: 30px 0;
}
.navbar-container{
    box-shadow: 0 2px 21px 0 rgba(0, 0, 0, 0.1);
    background: #fcfcfc !important;
    height: 93px;
}
.side-bar-btn{
    background: #424141 !important;
    border-radius: var(--lg-radius) !important;
    color: #fff !important;
    height: auto !important;
    width: 100% !important;
    justify-content: start !important;
    font-size: 16px !important;
    padding: 1px 10px !important;
    text-decoration: none !important;
}
.side-bar-btn.active{
    background: #f6b119 !important;
}
.sub-link-active {
    text-decoration: none !important;
    margin-inline-start: 0% !important;
    padding-inline-start: 38px !important;
}
.sub-link-active.active button{
    background: #f6b119 !important;
    border: 1px solid #fcfcfc !important;
    border-radius: 10px !important;
    width: 90% !important;
}
.side-bar-btn.active.remove-active{ background-color: #424141 !important; }


.navbar-container + div{ background-color: #fcfcfc !important; }