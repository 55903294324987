.organization-table.flow-table.payment-meth-table table tbody tr > * {
    padding: 14px 5px !important;
}
.organization-table.flow-table.payment-meth-table table thead tr th:nth-of-type(2),
.organization-table.flow-table.payment-meth-table table tbody tr td:nth-of-type(2){
    padding-left: 50px !important;
}
.organization-table.flow-table.payment-meth-table table tbody tr td div.MuiPopper-root{
    left: 0px!important;
}

.organization-table.flow-table.payment-meth-table .attribute-found,
.organization-table.flow-table.payment-meth-table .attribute-not-found {
  margin-right: auto;
  margin-left: 0px !important;
}

.flow-form.payment-meth-form{
    max-width: 310px !important;
    padding: 21px 30px !important;
}

.flow-form.payment-meth-form input, span.MuiTypography-root.MuiTypography-body1{
    font-size: 18px !important;
    font-weight: bold !important;    
}

.paymentLookupKey input, .paymentSource input{
    color: #bebebe !important;
    font-size: 18px !important;
    font-weight: bold !important;
}

span.MuiCheckbox-root svg{
    width: 20px;
    height: 20px;
}
.payment-method-organization-table table thead tr p,
.payment-method-organization-table table tbody td div {
    min-width: fit-content !important;
}