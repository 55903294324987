.delete-modal{
    position: absolute;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
    width: 35% !important;
    margin: auto;
    padding: 30px 60px;
    object-fit: contain;
    border-radius: 10px;
    box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.1);
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: none !important;    
}

.delete-modal > h1{    
    margin: 0rem auto 1rem !important;
}

.delete-modal > p{    
    font-size: 20px;
    line-height: 1.67;
    font-weight: bold;    
    max-height: 187px;
    overflow: auto;    
}


.delete-modal > div.buttons-wrap{
    flex-wrap: wrap;
    justify-content: center;
}
.delete-modal > div.buttons-wrap > button{
    font-size: 18px !important;
    font-weight: 500 !important;
    line-height: 1.11 !important;
    padding: 1.1rem !important;
    width: 170px !important;
    text-transform: capitalize !important;
}


.MuiModal-backdrop{
    opacity: 0.2 !important;
    background-color: #000 !important;
}