.activity-head li.MuiBreadcrumbs-separator{ 
    font-size: 20px;
    color: #bebebe;
}
.activity-head .MuiBreadcrumbs-root a,
.activity-head .MuiBreadcrumbs-root p{
    text-decoration: none !important; 
    font-size: 18px;
    color: #bebebe;
}

.activity-head .Search-activity fieldset{
    border: none;
    outline: none;
    box-shadow: none;
} 
.Search-activity > div{
    padding: 9px 15px 8px !important;
    border-radius: 10px !important;
    box-shadow: inset 0 1px 4px 0 rgba(0, 0, 0, 0.1) !important;
    background-color: #fff !important;
    color: #333132;
    border-radius: 10px;
    border: solid 1px #ececec;
}

.Search-activity > div > input{
    padding: 0px !important;
}

.activity-head .switch-btn{
    margin-bottom: 0px !important;
    display: flex !important;
    align-items: center;
    font-size: 16px;
    font-weight: 600;
    color: #333132;
    text-transform: capitalize;
}

#filter-menu ul.MuiList-root.MuiMenu-list{ max-height: 771px !important; overflow: auto; }
div[id^='react-select']{ position: static !important; }


#filter-menu .reservation-search .MuiInputBase-root.MuiInputBase-formControl{
    height: 50px;
}

#filter-menu .reservation-search .MuiInputAdornment-root.MuiInputAdornment-positionStart{ margin: 0px !important; }

#filter-menu .searchable-select{ box-shadow: none !important; }

#filter-menu .searchable-select div[class$="placeholder"],
#filter-menu .reservation-search input.MuiInputBase-input.MuiOutlinedInput-input::placeholder{
    color: #848383 !important; 
}
#filter-menu .reservation-search input.MuiInputBase-input.MuiOutlinedInput-input::placeholder{
    opacity: 1;
}

#filter-menu .searchable-select div[class$="control"],
#filter-menu .reservation-search .MuiInputBase-root.MuiInputBase-formControl{
    border: 1px solid #bebebe !important;
    box-shadow: none !important;
    border-radius: 10px !important;
}

#filter-menu .reservation-search fieldset{
    border: none !important;    
}

#filter-menu span[class$='indicatorSeparator']{
    display: none !important;
}
#filter-menu div[class$='indicatorContainer'] svg{
    color: #848383 !important;
}

#filter-menu .apply-btn{ background-color: #f6b119 !important; }

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}