.details-text{ font-size: 18px !important;     font-weight: 500 !important; overflow-wrap: anywhere;}
.details-text-bold{ font-size: 18px !important;    font-weight: bold !important; overflow-wrap: anywhere;}
.details-text-light{ font-size: 13px !important;  font-weight: normal !important;  color: #aaa; overflow-wrap: anywhere;}


.MuiBreadcrumbs-ol  .MuiSvgIcon-root{ color: #bebebe;  font-size: 18px; }

.details-panel .details-time-sm{ display: none; }

@media screen and (max-width: 992px) {
    .details-panel { flex-direction: column !important; }
    .details-panel > div{ width: 100% !important; }    
    .details-panel .details-time{ flex-wrap: wrap; }
    .details-panel .details-time-sm{ display: flex !important; }
    .details-panel .details-time-lg{ display: none !important; }
    .details-panel .details-location{ flex-direction: column !important;      align-items: flex-start; }
}

.details-info{
    display: flex;
    align-items: center;
    gap: 0.75rem;
}
.details-info svg.MuiSvgIcon-root{ font-size: 18px; }


.select-lang-wrap{
    align-items: center;
    gap: 1rem;
}
.select-lang-wrap > .MuiFormControl-root.MuiFormControl-fullWidth{
    width: auto;
}
.select-lang-wrap .select{ cursor: pointer; }
.select-lang-wrap .select .MuiSelect-select.MuiInputBase-input{
    width: 100%;
    padding-right: 0px;
    border-top: 1px solid #f6f6f6;
    border: none;
    font-size: 16px !important;
    font-weight: bold !important;
    color: #333132 !important;
}
.select-lang-wrap .select svg{
    color: #333132 !important;
    font-size: 20px !important;
}

.select-lang-wrap .select fieldset{
    border: none;
    box-shadow: none;
}

.select-lang-wrap .select input::placeholder{ color: #333132; }

.select-lang-wrap > p{
    font-size: 16px !important;
    font-weight: bold !important;
    color: #333132 !important;
}

.select-lang-wrap .MuiFormControl-root > label.MuiFormLabel-root{ display: none; }
.select-lang-wrap > .MuiGrid-container > .MuiGrid-root.MuiGrid-container.MuiGrid-item>.MuiGrid-root.MuiGrid-item:nth-of-type(2){ margin: 0px !important; max-width: 15rem !important; }
.select-lang-wrap > .MuiGrid-container > .MuiGrid-root.MuiGrid-container.MuiGrid-item>.MuiGrid-root.MuiGrid-item:nth-of-type(2) > .MuiGrid-item{     margin: 0;
    width: 100%; }
.select-lang-wrap > .MuiGrid-container > .MuiGrid-root.MuiGrid-container.MuiGrid-item>.MuiGrid-root.MuiGrid-item:not(.MuiGrid-container){
    width: auto;
    max-width: max-content;
    margin-right: 1rem !important;
}