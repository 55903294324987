
.user-form {
    box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.1);
    background: #fff;
    border-radius: var(--lg-radius);
    padding: 20px;
    margin: 10px;
    margin-bottom: 30px;
}
.user-form .MuiSelect-select{
    padding-right: 20px !important;
    padding-left: 10px !important;
    border-radius: 10px;
    box-shadow: inset 0 1px 5px 0 rgba(0, 0, 0, 0.2);
}

.user-form svg.MuiSelect-icon{
    right: 4px !important;
    color: var(--primary-color) !important;
}

.user-form .MuiFormLabel-root{
    color: #00000052 !important;
    background-color: #fff;
}

.user-form #name, .user-form #role_id, .user-form #email, .user-form #phone_number, .user-form #password, .user-form #password_confirmation{
    padding-inline: 14px !important;
    border-radius: 10px;
    box-shadow: inset 0 1px 5px 0 rgba(0, 0, 0, 0.2);        
    font-size: 18px;
}

.user-form #phone_number::-webkit-outer-spin-button,
.user-form #phone_number::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.user-form .MuiOutlinedInput-root{
    border: none !important;
}

.user-form .MuiOutlinedInput-root fieldset{
    border: none;
    outline: none;
    box-shadow: none !important;
}


.user-form .activity-img-container{
    max-width: 100% !important;
}

.user-form-image .add-activity-img {
    width: 140px !important;
    height: 140px;
    padding: 0px !important;
    margin-left: 10px !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.user-form-image .add-activity-img img {
    margin: 0px 0px 10px !important;
    padding-top: 10px !important;    
}

.user-form-image .activity-form{ box-shadow: none !important; }

.user-form .searchable-select.role-select{ 
    position: relative !important;
    margin-right: 14px !important;    
}
.user-form .searchable-select.role-select > div[id^='react-select']{ 
    background-color: #fff !important; 
    position: absolute !important;
}
.user-form .searchable-select.role-select div[id^='react-select'] ul{ min-height: 8rem !important; }
.user-form .searchable-select.role-select div[id$='placeholder'] { 
    min-height: auto !important; 
    color: #bbbbbb !important;
}
.user-form .searchable-select.role-select > div[class$='control']{ 
    border: none !important;
    padding-inline: 14px !important;
    border-radius: 10px !important;
    box-shadow: inset 0 1px 5px 0 rgba(0, 0, 0, 0.2) !important;
    font-size: 18px !important;
}

.user-form .searchable-select.role-select span[class$="indicatorSeparator"]{ display: none !important; }
.user-form .searchable-select.role-select + button.MuiButtonBase-root.MuiIconButton-root{ right: 55px !important; }
.user-form .searchable-select.role-select > div[class$='control'] > div[class$='ValueContainer']{
    max-width: calc(100% - 50px) !important;
    overflow-wrap: anywhere !important;
}
.user-form .searchable-select.role-select > div[class$='control'] > div[class$='ValueContainer'] > div[class$='singleValue']{
    white-space: inherit !important;
}
.special-label {
    color: rgba(0, 0, 0, 0.6);
    font-family: Catamaran;
    font-weight: 400;
    font-size: 12px;
    line-height: 1.4375em;
    padding: 0px 14px;
    user-select: none;
}
.phone-input-class:focus-visible{
    border: none !important;
    outline: none !important;
}
.phone-input-class::placeholder{ color: #bbbbbb !important; }