.search-select {
  height: 54px !important;
}
.search-select div div input {
  box-shadow: none !important;
}
.search-select div div {
  /* box-shadow: inset 0 1px 5px 0 rgba(0, 0, 0, 0.2) !important; */
}
.search-select div,
.search-select div:focus {
  padding: 2.5px !important;
}
.verif-code > div {
  width: 20rem !important;
  border-radius: 10px;
  background-color: #f9f9f9;
  padding-inline-start: 15px;
  margin: auto;
}

.verif-code > div > input {
  width: 33px !important;
  border: none !important;
  border-bottom: 2px solid grey !important;
  margin-left: 3%;
  margin-right: 3%;
  border-radius: 0 !important;
  background-color: #f9f9f9;
  margin-bottom: 10px;
}

.special-date fieldset {
  border-color: gray !important;
  color: black !important;
}

.special-date label {
  border-color: gray !important;
  color: black !important;
}

.table-header {
  color: #818181 !important;
  font-weight: bold !important;
}

.activity-item {
  background: #fff;
  border: 1px solid #d9d9d9;
  border-radius: var(--lg-radius);
  padding: 15px 0;
  line-height: 3;
}
