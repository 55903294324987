
.details-header-content-chip{
    background-color: #fff !important;
    padding: 0px 15px !important;
    align-items: center !important;
    gap: 15px !important;
}

.details-header-content-chip > span{
    filter: blur(10);
    font-size: 16px;
    font-weight: bold;
    padding: 0;
    display: block;
}

.details-header-content-chip-bronze > span{ color: #a3712a; }
.details-header-content-chip-gold > span{ color: #e6b54d; }
.details-header-content-chip-platinum > span{ color: #abd0e4; }
.details-header-content-chip-silver > span{ color: #a2a2a2; }