.details-text{ font-size: 18px !important;     font-weight: 500 !important;}
.details-text-bold{ font-size: 18px !important;    font-weight: bold !important; }
.details-text-light{ font-size: 13px !important;  font-weight: normal !important;  color: #aaa; }


.MuiBreadcrumbs-ol  .MuiSvgIcon-root{ color: #bebebe;  font-size: 18px; }

.details-panel .details-time-sm{ display: none; }

@media screen and (max-width: 992px) {
    .details-panel { flex-direction: column !important; }
    .details-panel > div{ width: 100% !important; }    
    .details-panel .details-time{ flex-wrap: wrap; }
    .details-panel .details-time-sm{ display: flex !important; }
    .details-panel .details-time-lg{ display: none !important; }
    .details-panel .details-location{ flex-direction: column !important;      align-items: flex-start; }
}

.details-info{
    display: flex;
    align-items: center;
    gap: 0.75rem;
}
.details-info svg.MuiSvgIcon-root{ font-size: 18px; }

.operators-container .filter-menu-wrap .filter-menu-button {
    padding: 10px 15px 8px !important;
}