.select-lang-wrap{
    align-items: center;
    gap: 1rem;
}
.select-lang-wrap > .MuiFormControl-root.MuiFormControl-fullWidth{
    width: auto;
}
.select-lang-wrap .select{ cursor: pointer; }
.select-lang-wrap .select .MuiSelect-select.MuiInputBase-input{
    /* width: 202px; */
    width: 100%;
    padding-right: 0px;
    border-top: 1px solid #f6f6f6;
    border: none;
    font-size: 16px !important;
    font-weight: bold !important;
    color: #333132 !important;
}
.select-lang-wrap .select svg{
    color: #333132 !important;
    font-size: 20px !important;
}

.select-lang-wrap .select fieldset{
    border: none;
    box-shadow: none;
}

.select-lang-wrap .select input::placeholder{ color: #333132; }

.select-lang-wrap > p{
    font-size: 16px !important;
    font-weight: bold !important;
    color: #333132 !important;
}

.select-lang-wrap .MuiFormControl-root > label.MuiFormLabel-root{ display: none; }
.select-lang-wrap > .MuiGrid-container > .MuiGrid-root.MuiGrid-container.MuiGrid-item>.MuiGrid-root.MuiGrid-item:nth-of-type(2){ margin: 0px !important; }
.select-lang-wrap > .MuiGrid-container > .MuiGrid-root.MuiGrid-container.MuiGrid-item>.MuiGrid-root.MuiGrid-item:nth-of-type(2) > .MuiGrid-item{     margin: 0;
    width: 100%; }
.select-lang-wrap > .MuiGrid-container > .MuiGrid-root.MuiGrid-container.MuiGrid-item>.MuiGrid-root.MuiGrid-item:not(.MuiGrid-container){
    width: auto;
    max-width: max-content;
    margin-right: 1rem !important;
}

.map-wrap .details-text {
    color: #fff;
    background-color: #f6b119;
    border-radius: 10px 10px 0px 0px;
    text-align: center;
    font-weight: 500 !important;
    font-size: 14px !important;
}

.map-wrap > div {
    height: 210px !important;
    margin-bottom: 10px !important;
    border-radius: 0px 0px 10px 10px !important;
}
.map-wrap > .map-wrap-address{
    font-size: 13px;
    color: #333132;
    display: flex;
    align-items: center;
}

.map-wrap > .map-wrap-address > img{
    width: 6.6px;
    height: 9.9px;
    margin: 0.8px 7.6px 1.3px 0;
}


.carousel-activity ul.react-multi-carousel-track{ gap: 15px; }

.carousel-activity ul.react-multi-carousel-track li.carousel-activity-item > div img{
    width: 100%;    max-width: 142px;
    height: 142px;
}


.carousel-activity ul.react-multi-carousel-track li.carousel-activity-item > *{ user-select: none; }