.organization-table.badges-table table thead th:first-of-type>div {
    /* padding-left: 50px !important; */
}

.organization-table.badges-table table tbody tr>* {
    padding: 14px 0px 14px 0px !important;
}

.organization-table.badges-table table thead tr th:nth-of-type(2),
.organization-table.badges-table table tbody tr td:nth-of-type(2) {
    padding-left: 50px !important;
}

.organization-table.badges-table table tbody tr>td:nth-of-type(3)>div {
    font-weight: bold !important;
}

.organization-table.badges-table table tbody tr td div.MuiPopper-root {
    left: 0px !important;
}

.flow-form.badges-form{ height: inherit !important; }

.flow-form.badges-form input {
    box-shadow: none !important;
}

.flow-form.badges-form label[for^="upload"]>div {
    gap: 10px !important;
}

.flow-form.badges-form label[for^="upload"]>div p {
    width: auto !important;
}

