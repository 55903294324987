.reservation-table {
  box-shadow: none !important;
  min-height: 82vh;
}
.reservation-table > div.MuiStack-root {
  flex-wrap: wrap;
  gap: 1rem 0rem;
}
.reservation-table > div.MuiStack-root p {
  font-size: 30px;
  font-weight: bold;
  text-align: left;
  color: #333132;
  margin-right: 2rem;
  margin-bottom: 0rem !important;
}

.reservation-table .search-input {
  padding: 12px 15px 10px !important;
  background-color: #fff !important;
  color: #333132;
  border: solid 1px #ececec;
  border-radius: 10px;
}
.reservation-table .search-input > .MuiInputBase-root {
  /* padding: 0px !important;
  border: none !important; */
}
.reservation-table .search-input fieldset {
  border: none;
  outline: none;
  box-shadow: none;
}

.reservation-table
  > div.MuiStack-root
  > div.MuiStack-root
  > div.MuiStack-root
  button {
  /* padding: 0px !important;
  margin-right: 8px !important; */
}

.reservation-table
  > div.MuiStack-root
  > div.MuiStack-root
  > div.MuiStack-root
  .MuiInputBase-root {
  margin-right: 0px !important;
}
.reservation-table
  > div.MuiStack-root
  > div.MuiStack-root
  > div.MuiStack-root
  input {
  padding: 0px !important;
  width: 13.5rem;
}

.reservation-table .basic-date-picker .MuiFormControl-root {
  border-top: 1px solid #ececec;
  box-shadow: none;
  border-radius: 10px;
  width: 12.5rem;
}
.reservation-table .basic-date-picker label,
.reservation-table .basic-date-picker input {
  font-size: 16px;
  font-weight: bold;
  color: #333132;
}
.reservation-table .basic-date-picker label {
  line-height: 1em !important;
}
.reservation-table .basic-date-picker input {
  padding: 12.5px 14px !important;
}
.reservation-table
  .basic-date-picker
  label.MuiFormLabel-root.MuiInputLabel-formControl.MuiInputLabel-shrink {
  display: none !important;
}
.reservation-table .basic-date-picker fieldset {
  border: none !important;
  box-shadow: none !important;
}

.reservation-table button.MuiButtonBase-root.MuiButton-root {
  margin: 0px !important;
  width: auto !important;
}

#filter-menu ul.MuiList-root.MuiMenu-list {
  max-height: 404px;
  overflow: auto;
  width: 330px !important;
  padding-bottom: 0px !important;
}
.filter-menu-item-head {
  font-size: 16px !important;
  font-weight: 500 !important;
  color: #333132 !important;
  text-transform: capitalize !important;
}

.reservation-table hr.MuiDivider-root {
  border-color: #d9d9d9 !important;
}

.reservation-table table thead th > div {
  font-size: 20px;
  font-weight: 500;
  color: #818181;
}
.reservation-table table thead th:first-of-type > div {
  justify-content: center;
}

table {
  border-collapse: separate !important;
  border-spacing: 0px 20px !important;
  padding: 0px 1px;
}

/* tr td:first-child { border-left: 0.5px solid #d9d9d9 !important; border-radius: 10px; }
tr td:last-child { border-right: 0.5px solid #d9d9d9 !important; border-radius: 10px; } */
.reservation-table tr td:first-child {
  border-left: none !important;
}
.reservation-table tr td:last-child {
  border-right: none !important;
}
@media screen and (max-width: 992px) {
  .reservation-table table thead tr {
    display: flex;
    flex-wrap: wrap;
  }
  .reservation-table table tbody tr {
    display: flex;
    flex-wrap: wrap;
  }
}
.reservation-table table thead tr > * {
  padding: 5px 5px 0px;
}
.reservation-table table thead tr th {
  border-bottom: none !important;
  min-width: 200px;
}
.reservation-table table thead tr th:first-of-type {
  width: 3rem;
  text-align: center;
  text-transform: uppercase;
}

.reservation-table table tbody tr {
  background: #fff !important;
  outline: 1px solid #d9d9d9 !important;
  padding: 15px 0 !important;
  border-radius: 10px !important;
}

.reservation-table table tbody tr td:first-of-type,
table tbody tr td:nth-of-type(2) {
  font-weight: bold;
}
.reservation-table table tbody tr td:first-of-type {
  color: #ccc !important;
  text-align: center;
  width: 3rem;
}

.reservation-table table thead tr p {
  font-size: 20px !important;
}
.reservation-table table tbody tr > * {
  text-transform: capitalize;
  font-size: 20px;
  font-weight: normal;
  color: #333132;
  word-break: break-word;
  padding: 27px 5px;
}
.reservation-table table tbody tr > td > * {
  text-transform: inherit !important;
  font-size: inherit !important;
  font-weight: inherit !important;
  word-break: inherit !important;
  padding: 0px !important;
}

.reservation-table
  table
  tbody
  tr
  > td
  > *:not(.reservation-status, .reservation-paid, .reservation-unpaid) {
  color: inherit !important;
}

.reservation-table table tbody tr button.reservation-modal-button {
  display: inline-block !important;
  padding: 0px !important;
  border: none !important;
  width: fit-content !important;
  height: fit-content !important;
  float: right;
}

.reservation-table table tbody tr ul {
  margin-top: 11px;
}
.reservation-table table tbody tr ul li {
  white-space: pre-wrap;
}

.reservation-table .reservation-customer-name {
  font-weight: bold !important;
  text-decoration: underline;
  cursor: pointer;
}
.reservation-table .reservation-total-price {
  font-weight: bold !important;
}

.reservation-table .reservation-status {
  font-size: 18px !important;
}
.reservation-table .reservation-paid,
.reservation-table .reservation-unpaid {
  font-weight: 600 !important;
  /* padding: 7px 22px 8px;
    border-radius: 10px;
    color: white;
    width: 33px;
    max-width: 33px !important;
    text-align: center;
    margin: auto; */
}

.reservation-table .reservation-paid {
  color: #00b906 !important;
}
.reservation-table .reservation-unpaid {
  color: #e10000 !important;
}
