.table-header.activity-header > div{
    font-size: 20px;
    font-weight: 500;
    color: #818181;
}

.activity-verified, .activity-not-verified{
    padding: 0px 22px;
    border-radius: 10px;
    color: white;
    width: 33px;
    max-width: 33px !important;
    text-align: center;
    height: 48px;
    display: flex;
    align-items: center;
}

.activity-verified{
    background-color: #62c08b;
}
.activity-not-verified{
    background-color: #cc4d49;
}

.activity-item{
    background: #fff;
    border: 1px solid #d9d9d9;    
    padding: 15px 0;
    align-items: center;
}

.activity-item-no, .activity-item-name {
    font-weight: bold;
}

.activity-item-no {
    color: #ccc;
}


.activity-item > *{     
    font-size: 20px;
}
.activity-item > *::first-letter{ text-transform: capitalize !important; }
.activity-item button{
    font-size: 16px;
    background-color: transparent;
    padding: 0;
    margin-left: 1rem;
    border: none;
    cursor: pointer;
}
.activity-item button::first-letter{ text-transform: capitalize !important; }