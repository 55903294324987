button[id^='demo-positioned-btn']{
    background-color: white;
    padding: 4px;
    min-width: auto;
    border-radius: 10px;
}

button[id^='demo-positioned-btn']:hover{
    background-color: white !important;
}


@media screen and (max-width: 992px) {
    .details-header-content { flex-direction: column !important; gap: 1rem; }
}

ul.MuiList-root.MuiMenu-list li.MuiButtonBase-root.MuiMenuItem-root{
    font-weight: 500;
}