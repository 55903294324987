div.MuiGrid-root.MuiGrid-item.form-element {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    position: relative;
}

#user_email, #user_name{
    padding: 0.5rem;
    border-radius: 10px;
    box-shadow: inset 0 1px 5px 0 rgba(0, 0, 0, 0.2);        
}
div.MuiGrid-root.MuiGrid-item.form-element fieldset{
    border: none !important;
    outline: none;
    box-shadow: none;
}

div.MuiGrid-root.MuiGrid-item.form-element .MuiInputBase-fullWidth{
    padding: 0px!important;
}

div.MuiGrid-root.MuiGrid-item.form-element .css-ittuaa-MuiInputAdornment-root{
    margin-right: 0px !important;
}

div.MuiGrid-root.MuiGrid-item.form-element .css-1laqsz7-MuiInputAdornment-root{
    margin-left: 0px !important;
}

div.MuiGrid-root.MuiGrid-item.form-element .MuiOutlinedInput-root{
    border-radius: 10px !important;
    border: none !important;
}

div.MuiGrid-root.MuiGrid-item.form-element .MuiFormHelperText-root.Mui-error{
    text-transform: capitalize;
}