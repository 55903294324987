.organization-table.language-fluency-table table thead tr th:first-of-type {
    padding-left: 30px !important;
}

.organization-table.language-fluency-table table tbody tr td:first-of-type {
    padding-left: 45px !important;
}

.organization-table.language-fluency-table table thead tr th:nth-of-type(2),
.organization-table.language-fluency-table table tbody tr td:nth-of-type(2) {
    padding-left: 50px !important;
}

.organization-table.language-fluency-table table tbody tr td div.MuiPopper-root {
    left: 0px !important;
}

.flow-form.language-fluency-form {
    max-width: 320px !important;
    padding: 30px 23px 28px !important;
}

.flow-form.language-fluency-form .MuiInputBase-root {
    max-width: 310px !important;
}

.flow-form.language-fluency-form input {
    box-shadow: none !important;
}

.language-fluency-table table thead tr p,
.language-fluency-table table table tbody td div {
    min-width: max-content !important;
}