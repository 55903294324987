.details-text{ font-size: 18px !important; }
.details-text-bold{ font-size: 18px !important;    font-weight: bold !important; }

h1.MuiTypography-root::first-letter{
    text-transform: capitalize;
}

.MuiBreadcrumbs-ol  .MuiSvgIcon-root{ color: #bebebe;  font-size: 18px; }

.details-panel .details-time-sm{ display: none; }

@media screen and (max-width: 992px) {
    .details-panel { flex-direction: column !important; }
    .details-panel > div{ width: 100% !important; }    
    .details-panel .details-time{ flex-wrap: wrap; }
    .details-panel .details-time-sm{ display: flex !important; }
    .details-panel .details-time-lg{ display: none !important; }
    .details-panel .details-location{ flex-direction: column !important;      align-items: flex-start; }
}

.details-info{
    display: flex;
    align-items: center;
    gap: 0.75rem;
}
.details-info svg.MuiSvgIcon-root{ font-size: 18px; }



.freelancer-carousel .carousel-location ul.react-multi-carousel-track li.carousel-location-item > div.MuiStack-root{
    width: 208px;
    height: 149px;
}

.freelancer-carousel .carousel-location ul.react-multi-carousel-track li.carousel-location-item{
    min-width: fit-content;
    z-index: 9;
}

.freelancer-carousel .carousel-location ul.react-multi-carousel-track li.carousel-location-item > *{ user-select: none; }



.freelancer-carousel .carousel-certificate ul.react-multi-carousel-track li.carousel-certificate-item{
    min-width: fit-content;
    padding: 0px 40px 0px 0px !important;
    z-index: 9;
}

.freelancer-carousel .carousel-certificate ul.react-multi-carousel-track li.carousel-certificate-item > div.MuiStack-root{
    width: 178px;
}

.freelancer-carousel .carousel-certificate ul.react-multi-carousel-track li.carousel-certificate-item > *{ user-select: none; }

/* .carousel-certificate-item{
    padding: 0rem 2rem;
} */