.Search-applicant > div{
    padding: 9px 15px 8px !important;    
    box-shadow: inset 0 1px 4px 0 rgba(0, 0, 0, 0.1) !important;
    background-color: #fff !important;
    color: #333132;
    border-radius: 10px;
    border-top: solid 1px #ececec;
}

.Search-applicant input{ padding: 5px 0px !important; }
.Search-applicant fieldset{
    border: none !important;
    box-shadow: none !important;    
}