.table-header.activity-header > div{
    font-size: 20px;
    font-weight: 500;
    color: #818181;
}

/*.activity-verified, .activity-not-verified{
    padding: 7px 22px 8px;
    border-radius: 10px;
    color: white;
    width: 33px;
    max-width: 33px !important;
    text-align: center;
}

.activity-verified{
    background-color: #62c08b;
}
.activity-not-verified{
    background-color: #cc4d49;
}*/


.activity-offer-item{
    background: #fff;
    border: 1px solid #d9d9d9;
    /* border-radius: var(--sm-radius); */
    padding: 15px 0;
    align-items: center;
}

/* .activity-offer-item > div:not(:nth-of-type(2),:last-of-type){
    font-size: 20px;    
} */
.activity-offer-item-no, .activity-offer-item-name {
    font-weight: bold;
}

.activity-offer-item-no {
    color: #ccc !important;
}

/* .activity-offer-item > div:nth-of-type(2) img{
    max-width: 100%;
    height: 42px;
    width: 42px;
    border-radius: 50%;
} */

.activity-offer-item > *{ 
    text-transform: capitalize; 
    font-size: 20px;
    color: #333132;
}
.activity-offer-item button{
    font-size: 16px;
    background-color: transparent;
    padding: 0;
    margin-left: 1rem;
    border: none;
}