.role-item{
    background: #fff;
    border: 1px solid #d9d9d9;
    border-radius: var(--sm-radius);
    padding: 15px 0;
    align-items: center;
}

.role-item-no{
    color: #ccc;
}

.role-item-no, .role-item-name{
    font-size: 20px;
    font-weight: bold;
}

.role-item-button:hover{
    background-color: transparent !important;
}