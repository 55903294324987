.organization-table.price-include-table table thead tr th:first-of-type {
    padding-left: 30px !important;
}

.organization-table.price-include-table table tbody tr td:first-of-type {
    padding-left: 45px !important;
}

.organization-table.price-include-table table thead tr th:nth-of-type(2),
.organization-table.price-include-table table tbody tr td:nth-of-type(2) {
    padding-left: 50px !important;
}

.organization-table.price-include-table table tbody tr td div.MuiPopper-root {
    left: 0px !important;
}

.flow-form.price-include-form .MuiInputBase-root {
    max-width: 310px !important;
}

.flow-form.price-include-form input {
    box-shadow: none !important;
}

.price-include-table table thead tr p,
.price-include-table table tbody td div,
.price-include-table table tbody td {
    min-width: fit-content !important;
}