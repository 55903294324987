.table-header.applicant-header > div{
    font-size: 20px;
    font-weight: 500;
    color: #818181;
}


.applicant-item{
    background: #fff;
    border: 1px solid #d9d9d9;
    padding: 15px 0;
    align-items: center;
    border-radius: 10px;
}


.applicant-item-no, .applicant-item-name {
    font-weight: bold !important;
}

.applicant-item-name{
    font-size: 20px !important;
    text-align: left;
    color: #333132;
}

.applicant-item-no {
    color: #ccc !important;
}

.applicant-item > div:nth-of-type(2) img{
    max-width: 100%;
    width: 53px;
    height: 53px;
    border-radius: 10px;
}

.applicant-item > *{ 
    text-transform: capitalize; 
    font-size: 20px;
    color: #333132;
    word-break: break-word;
}

.applicant-text{
    font-size: 20px !important;
    color: #333132;
}
.applicant-item button{
    font-size: 16px;
    background-color: transparent;
    padding: 0;
    margin-left: 1rem;
    border: none;
    cursor: pointer;
    font-weight: 500;
}

@media (min-width: 900.1px) and (max-width: 1200px){
    .applicant-header, .applicant-item{
        gap: 1rem;
    }
}

@media (max-width: 900px){
    .applicant-header, .applicant-item{
        gap: 0.5rem;
    }
}