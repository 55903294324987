button[id^='demo-positioned-btn']{
    background-color: white;
    padding: 4px;
    min-width: auto;
    border-radius: 3px;
    border: 1px solid #ededed;
}

button[id^='demo-positioned-btn']:hover{
    background-color: white !important;
}


@media screen and (max-width: 992px) {
    .details-header-content { flex-direction: column !important; gap: 1rem; }
}

ul.MuiList-root.MuiMenu-list li.MuiButtonBase-root.MuiMenuItem-root{
    font-weight: 500;
}

.navigate-button{
    height: 50px !important;
    padding: 0rem 3.35rem !important;
    display: flex !important;
    align-items: center !important;
    border-radius: 26px !important;
    background-color: #f6b119 !important;
    font-size: 18px !important;
    color: #ffffff !important;
    text-transform: lowercase !important;
}