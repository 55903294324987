.map-wrap > div {
    height: 210px !important;
    margin-bottom: 10px !important;
}
.map-wrap > .map-wrap-address{
    font-size: 13px;
    color: #333132;
    display: flex;
    align-items: center;
}

.map-wrap > .map-wrap-address > img{
    width: 18.9px;
    height: 18.9px;
    margin: 0.8px 7.6px 1.3px 0;
}

.details-panel .details-time-sm{ display: none; }

@media screen and (max-width: 992px) {
    .details-panel { flex-direction: column !important; }
    .details-panel > div{ width: 100% !important; }    
    .details-panel .details-time{ flex-wrap: wrap; }
    .details-panel .details-time-sm{ display: flex !important; }
    .details-panel .details-time-lg{ display: none !important; }
    .details-panel .details-location{ flex-direction: column !important;      align-items: flex-start; }
}

.details-info{
    display: flex;
    align-items: center;
    gap: 0.75rem;
}
.details-info svg.MuiSvgIcon-root{ font-size: 18px; }


.organizations-wrap > p.details-text:not(:last-of-type){ margin-bottom: 10px; }

.carousel-branch ul.react-multi-carousel-track{ gap: 15px; }

.carousel-branch ul.react-multi-carousel-track li.carousel-branch-item > div img{
    width: 100%;    max-width: 142px;
    height: 142px;
}


.carousel-branch ul.react-multi-carousel-track li.carousel-branch-item > *{ user-select: none; }