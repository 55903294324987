.border-r-10px fieldset {
  border-radius: 10px !important;
}
.basic-date-picker .MuiFormControl-root {
  width: 100%;
  height: 100% !important;
}
.basic-date-picker svg {
  color: var(--primary-color);
}
.red-border {
  outline: 1.5px solid #d32f2f !important;
  border-radius: 10px !important;
}

.red-border > label {
  color: #d32f2f !important;
}

.MuiInputBase-root {
  height: 100% !important;
  padding: 3px;
}
