.organization-table.language-table table thead tr th:first-of-type {
    padding-right: 1.5rem;
}

.organization-table.language-table table tbody tr td:first-of-type {
    padding-right: 1.5rem;
}
.language-table table thead tr p,
.language-table table table tbody td div {
    min-width: max-content !important;
}