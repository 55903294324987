@import "./form-page.css";

.flow-form {
  padding: 22.5px 23px 37px 35px;
  border-radius: 10px;
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.1);
  background-color: #fff;
  width: 43%;
}

.add-organization-button {
  cursor: pointer !important;
  color: #fff !important;
  background-color: #f6b119 !important;
  border-radius: 26px !important;
  padding: 9px 50px !important;
}
.add-organization-button p {
  color: #fff !important;
  font-size: 18px !important;
  font-weight: 500 !important;
  text-transform: capitalize !important;
  margin: 0px !important;
}

.organization-table {
  box-shadow: none !important;
  min-height: 82vh;
}
.organization-table > div.MuiStack-root {
  flex-wrap: wrap;
  gap: 1rem 0rem;
}
.organization-table > div.MuiStack-root > div.MuiStack-root:first-of-type {
  flex-wrap: wrap;
  gap: 1rem 0rem;
}
.organization-table > div.MuiStack-root p {
  font-size: 30px;
  font-weight: bold;
  text-align: left;
  color: #333132;
  margin-right: 2rem;
  margin-bottom: 0rem !important;
}

.organization-table .search-input {
  padding: 12px 15px 10px !important;
  background-color: #fff !important;
  color: #333132;
  border: solid 1px #ececec;
  border-radius: 10px;
}
.organization-table .search-input > .MuiInputBase-root {
  padding: 0px !important;
  border: none !important;
}
.organization-table .search-input fieldset {
  border: none;
  outline: none;
  box-shadow: none;
}

.organization-table
  > div.MuiStack-root
  > div.MuiStack-root
  > div.MuiStack-root
  button {
  /* padding: 0px !important; */
  margin-right: 8px !important;
}

.organization-table
  > div.MuiStack-root
  > div.MuiStack-root
  > div.MuiStack-root
  .MuiInputBase-root {
  margin-right: 0px !important;
}
.organization-table
  > div.MuiStack-root
  > div.MuiStack-root
  > div.MuiStack-root
  input {
  padding: 0px !important;
  width: 13.5rem;
}

.organization-table .basic-date-picker .MuiFormControl-root {
  border-top: 1px solid #ececec;
  box-shadow: none;
  border-radius: 10px;
  width: 12.5rem;
}
.organization-table .basic-date-picker label,
.organization-table .basic-date-picker input {
  font-size: 16px;
  font-weight: bold;
  color: #333132;
}
.organization-table .basic-date-picker label {
  line-height: 1em !important;
}
.organization-table .basic-date-picker input {
  padding: 12.5px 14px !important;
}
.organization-table
  .basic-date-picker
  label.MuiFormLabel-root.MuiInputLabel-formControl.MuiInputLabel-shrink {
  display: none !important;
}
.organization-table .basic-date-picker fieldset {
  border: none !important;
  box-shadow: none !important;
}

.organization-table button.MuiButtonBase-root.MuiButton-root {
  margin: 0px !important;
  width: auto !important;
}

.organization-table hr.MuiDivider-root {
  border-color: #d9d9d9 !important;
}

.organization-table table thead th > div {
  font-size: 20px;
  font-weight: 500;
  color: #818181;
}
.organization-table table thead th:first-of-type > div {
  padding-left: 10px;
}

table {
  border-collapse: separate !important;
  border-spacing: 0px 20px !important;
  padding: 0px 1px;
}

.organization-table tr td:first-child {
  border-left: none !important;
}
.organization-table tr td:last-child {
  border-right: none !important;
}
@media screen and (max-width: 992px) {
  .flow-form {
    width: 100% !important;
  }
  .organization-table table thead tr,
  .organization-table table tbody tr {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem 1rem;
  }
}
.organization-table table thead tr > * {
  padding: 5px 5px 0px;
}
.organization-table table thead tr th {
  border-bottom: none !important;
}
.organization-table table thead tr th:first-of-type {
  width: 3rem;
  text-align: center;
  text-transform: uppercase;
}

.organization-table table tbody tr {
  background: #fff !important;
  outline: 1px solid #d9d9d9 !important;
  padding: 15px 0 !important;
  border-radius: 10px !important;
}

.organization-table table tbody tr td:first-of-type,
table tbody tr td:nth-of-type(2) {
  font-weight: bold;
}
.organization-table table tbody tr td:first-of-type {
  color: #ccc !important;
  padding-left: 20px;
  width: 3rem;
}

.organization-table table thead tr p {
  font-size: 20px !important;
  max-width: 93px !important;
}
.organization-table table tbody tr > * {
  font-size: 20px;
  font-weight: normal;
  color: #333132;
  word-break: break-word;
  padding: 27px 5px;
}
.organization-table table tbody tr > td > * {
  text-transform: inherit !important;
  font-size: inherit !important;
  font-weight: inherit !important;
  word-break: inherit !important;
  padding: 0px !important;
  color: inherit;
}

.organization-table table tbody tr button {
  font-size: 16px !important;
  font-weight: 500 !important;
  color: #333132 !important;
  text-decoration: underline;
  background-color: transparent !important;
  border: none !important;
  cursor: pointer;
}

.organization-table table tbody tr td:last-of-type button {
  font-size: 16px !important;
  background-color: transparent !important;
  cursor: pointer !important;
  border: 1px solid #ededed !important;
  border-radius: 10px !important;
  padding: 4px !important;
  width: 34px !important;
  max-width: 34px !important;
  display: block !important;
  height: 34px !important;
  margin-left: 0px !important;
  margin-right: auto !important;
}

.organization-table table tbody tr ul {
  margin-top: 11px;
}
.organization-table table tbody tr ul li {
  white-space: pre-wrap;
}

.organization-table .organization-found,
.organization-table .organization-not-found,
.flow-table .attribute-found,
.flow-table .attribute-not-found {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px !important;
  color: white !important;
  height: 48px;
  width: 75px;
  max-width: 75px !important;
  border-radius: 10px;
  margin: auto;
}

.organization-table .organization-found,
.flow-table .attribute-found {
  background-color: #62c08b;
}
.organization-table .organization-not-found,
.flow-table .attribute-not-found {
  background-color: #cc4d49;
}

.organizations-delete-modal.delete-modal {
  padding: 32px 60px 40px !important;
  border-radius: 10px !important;
}
.organizations-delete-modal.delete-modal h1 {
  padding: 0px 30px !important;
  text-align: center !important;
}
.organizations-delete-modal.delete-modal p {
  padding: 0px 60px !important;
  text-align: center !important;
}
.organizations-delete-modal.delete-modal .buttons-wrap {
  gap: 1rem !important;
}
.organizations-delete-modal.delete-modal .buttons-wrap > button {
  width: 130px !important;
}
.organizations-delete-modal.delete-modal .buttons-wrap > button:not(.primary) {
  border: solid 1px #ececec !important;
  background-color: rgba(250, 161, 0, 0) !important;
}
