.login-container{
    text-align: center;
    padding: 130px 0;
}
.login-form{
    background: #fcfcfc;
    border-radius: var(--xlg-radius);
    padding: 5%;
    margin-top: 130px;
}

.login-container .MuiInputBase-fullWidth{
    padding: 0px!important;
}

.login-container .MuiInputBase-fullWidth input#email,
.login-container .MuiInputBase-fullWidth input#password{
    padding-left: 20px !important;
    padding-right: 20px !important;
}

.login-container .css-ittuaa-MuiInputAdornment-root{
    margin-right: 0px !important;
}

.login-container .css-1laqsz7-MuiInputAdornment-root{
    margin-left: 0px !important;
}

.login-container .MuiOutlinedInput-root{
    border-radius: 10px !important;
    border: none !important;
}

.login-container .flag-dropdown:first-of-type{
    height: fit-content;
    padding: 27.5px 0px;
}

.login-container .flag-dropdown:first-of-type.open .react-tel-input .country-list{
    top: 100%;
}

.login-container button.primary{ background-color: #f6b119 !important; }
.login-container button.primary>p{ font-size: 18px !important; }