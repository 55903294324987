.dive-center-tabs {
    font-weight: 700 !important;
    color: #AAAAAA !important;
    font-size: 24px !important;
    text-transform: capitalize !important;
}

.filter-tabs-style {
    text-transform: capitalize !important;
    font-weight: 700 !important;
    color: #333132;
}

.dive-center-tabs.Mui-selected {
    border-bottom: 4px solid orange !important;
    color: #272726 !important;
}

.dive-center-tabs-container {
    border-bottom: 1px solid #D9D9D9 !important;
}

.salesmen-reservations-search {
    padding: 0px 0px !important;
    background-color: #fff;
}

.salesmen-reservations-search input {
    padding: 13.1px 0px !important;
    background-color: transparent !important;
}

.salesmen-reservations-search input::placeholder {
    /* color: #BEBEBE !important; */
    font-size: 18px !important;
    font-weight: 400 !important;
}

.salesmen-reservations-search fieldset {
    border: 1px solid #ececec !important;
    border-radius: 10px !important;
    background-color: transparent !important;
}



.salesmen-reservations-filters .MuiGrid-root.border-r-10px {
    background-color: #fff !important;
}

.salesmen-reservations-filters .MuiGrid-root.border-r-10px label.Mui-focused {
    z-index: 9;
}

.salesmen-reservations-filters .MuiGrid-root.border-r-10px input,
.salesmen-reservations-filters .MuiGrid-root.border-r-10px .searchable-select div[class$="placeholder"],
.salesmen-reservations-filters .MuiGrid-root.border-r-10px .searchable-select svg {
    color: #333132 !important;
}

.salesmen-reservations-filters .MuiGrid-root.border-r-10px fieldset,
.salesmen-reservations-filters .MuiGrid-root.border-r-10px .searchable-select>div {
    border: none !important;
    border-radius: 10px !important;
    background-color: transparent !important;
    box-shadow: 0px 0px 8px 0px #00000014 !important;
}

span[class$="indicatorSeparator"] {
    display: none !important;
}

/* .searchable-select div[id^='react-select'] {
    background-color: #ffffff !important;
} */

.searchable-select div[id^='react-select'] ul {
    min-height: 8rem !important;
}

.searchable-select div[id$='placeholder'] {
    min-height: auto !important;
}

.salesmen-reservations-header .border-r-10px {
    border-radius: 10px !important;
}

.branches-filter {
    border-radius: 10px !important;
}

.branches-filter>div {
    border: none !important;
    box-shadow: 0px 1px 4px 0px #0000001A !important;

}