.roles-form-container{
    box-shadow: 0 2px 15px 0 rgb(0 0 0 / 10%);
    background: #fff;
    border-radius: var(--lg-radius);
    padding: 20px;
    margin: 10px 10px 30px 0px;
}

.roles-form-container .table-header{
    font-size: 20px;
    font-weight: 500 !important;
}

.roles-form-container .role-item{
    font-size: 20px;
    font-weight: bold;
    text-align: left;
    color: #333132;
}

.roles-form-container input{
    padding: 10px 15px;
    border-radius: 10px;
    box-shadow: inset 0 1px 5px 0 #00000033;
    background-color: #fcfcfc;
    border: none;
    font-size: 18px;
}

.roles-form-container .MuiOutlinedInput-root fieldset{
    border: none;
    outline: none;
    box-shadow: none !important;
}

.roles-form-container p{
    font-size: 18px;
    color: #aaa;
}