.customer-carousel .carousel-certificate ul.react-multi-carousel-track li.carousel-certificate-item{
    width: auto !important;
    min-width: fit-content;    
    padding: 0px 40px 0px 0px;
    z-index: 9;
}
/* .carousel-certificate ul.react-multi-carousel-track li.carousel-certificate-item:first-of-type{ padding-left: 0px !important; } */
/* .carousel-certificate ul.react-multi-carousel-track li.carousel-certificate-item:last-of-type{ padding-right: 0px !important; } */

.customer-carousel .carousel-certificate ul.react-multi-carousel-track li.carousel-certificate-item > div.MuiStack-root{
    width: 178px;
}

.customer-carousel .carousel-certificate ul.react-multi-carousel-track li.carousel-certificate-item > *{ user-select: none; }