.organization-table table thead tr th:first-of-type{
    padding-left: 30px !important;
}
.organization-table table tbody tr td:first-of-type {
    padding-left: 45px !important;
}

.organization-table table thead tr th:nth-of-type(2),
.organization-table table tbody tr td:nth-of-type(2){
    padding-left: 50px !important;
}

.organization-table table tbody tr td div.MuiPopper-root {
    left: -50px!important;
}


.flow-form.organization-form .MuiOutlinedInput-root {    
    box-shadow: none !important;
    border: solid 1px #bebebe !important;
}
.flow-form.organization-form .MuiOutlinedInput-root input{
    box-shadow: none !important;
}
.flow-form.organization-form .MuiStack-root .MuiBox-root p.MuiTypography-root.MuiTypography-body1{ font-weight: bold !important; }

.organization-table-style table thead tr p,
.organization-table-style table tbody td div {
    min-width: fit-content !important;
}