.filter-menu-wrap .filter-menu-button{
    font-size: 16px !important;
    font-weight: bold;
    color: #333132;
    text-transform: capitalize;
}
.filter-menu-wrap .filter-menu-button > svg{ color: inherit !important; }

.filter-menu-list .MuiPaper-root.MuiMenu-paper{
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16) !important;
}

.filter-menu-list ul.MuiList-root.MuiMenu-list{
    /* width: 14rem; */
    width: 12.625rem;
}

.filter-menu-list ul.MuiList-root.MuiMenu-list li{
    text-transform: capitalize;
}