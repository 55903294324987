.flow-form.freelancer-levels-form{
    width: auto !important;
    max-width: 777px !important;
}

.flow-form.freelancer-levels-form .MuiStack-root .MuiBox-root:nth-last-of-type(2){
    display: flex;
    align-items: center;
}

.freelances-table table thead tr p,
.freelances-table table tbody td div,
.freelances-table table tbody tr td {
    min-width: max-content !important;
}