.activity-offer-head li.MuiBreadcrumbs-separator{ 
    font-size: 20px;
    color: #bebebe;
}
.activity-offer-head .MuiBreadcrumbs-root a,
.activity-offer-head .MuiBreadcrumbs-root p{
    text-decoration: none !important; 
    font-size: 18px;
    color: #bebebe;
}

.Search-activity{
    width: 330px !important;    
}

.activity-offer-head .Search-activity fieldset{
    border: none;
    outline: none;
    box-shadow: none;
} 
.Search-activity > div{
    padding: 9px 15px 8px !important;
    border-radius: 10px !important;
    box-shadow: inset 0 1px 4px 0 rgba(0, 0, 0, 0.1) !important;
    background-color: #fff !important;
    color: #333132;
    border-radius: 10px;
    border: solid 1px #ececec;
}

.Search-activity > div > input{
    padding: 0px !important;
}

.activity-offer-head .switch-btn{
    margin-bottom: 0px !important;
    display: flex !important;
    align-items: center;
    font-size: 16px;
    font-weight: 600;
    color: #333132;
}


#filter-menu .basic-date-picker label.MuiFormLabel-root.MuiInputLabel-formControl.MuiInputLabel-shrink{ display: none !important; }
#filter-menu .basic-date-picker label.MuiFormLabel-root.MuiInputLabel-formControl:not(.MuiInputLabel-shrink){ z-index: 0 !important; }
#filter-menu .basic-date-picker fieldset legend{ width: 0px !important; }
/* #filter-menu .basic-date-picker input.MuiInputBase-input.MuiInputBase-inputAdornedEnd{ font-size: 0.78rem !important; } */
/* #filter-menu .basic-date-picker input.MuiInputBase-input.MuiInputBase-inputAdornedEnd::placeholder{ font-size: 0.65rem !important; } */
#filter-menu .basic-date-picker .MuiInputAdornment-root.MuiInputAdornment-positionEnd { margin-left: 5px; }
/* #filter-menu .basic-date-picker .MuiInputAdornment-root.MuiInputAdornment-positionEnd button{ padding: 8px 0px !important; } */
/* #filter-menu .basic-date-picker .MuiInputAdornment-root.MuiInputAdornment-positionEnd button svg{ font-size: 1.2rem !important; } */



#filter-menu.activity-offers-menu ul.MuiList-root.MuiMenu-list{ max-height: 706px; overflow: auto; width: 390px !important; }
div[id^='react-select']{ position: static !important; }

#filter-menu .reservation-search input.MuiInputBase-input{ padding: 14px 10px !important; }
#filter-menu .reservation-search input.MuiInputBase-input.MuiOutlinedInput-input::placeholder{
    opacity: 1;
    color: #848383 !important;    
}
#filter-menu .reservation-search fieldset,
#filter-menu .basic-date-picker fieldset{ 
    border: none !important; 
}



#filter-menu .searchable-select{ box-shadow: none !important; }
#filter-menu .searchable-select div[class$="placeholder"]{ color: #848383 !important; }
#filter-menu .searchable-select div[class$="control"],
#filter-menu .basic-date-picker > .MuiFormControl-root.MuiTextField-root,
#filter-menu .reservation-search .MuiInputBase-root.MuiInputBase-formControl{
    border: 1px solid #bebebe !important;
    box-shadow: none !important;
    border-radius: 10px;
}

#filter-menu span[class$='indicatorSeparator']{
    display: none !important;
}
#filter-menu div[class$='indicatorContainer'] svg{
    color: #848383 !important;
}
