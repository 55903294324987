.operator-verified, .operator-not-verified{
    padding: 7px 22px 8px;
    border-radius: 10px;
    color: white;
    width: 33px;
    max-width: 33px !important;
    text-align: center;
}

.operator-verified{
    background-color: #62c08b;
}
.operator-not-verified{
    background-color: #cc4d49;
}

.operator-menu-button-wrap > button{
    margin-left: 1rem;
}