.toggle-btns-container div {
    padding: 1rem;
}
.toggle-btns-container.appointments div{
    padding: 1px;
}
.toggle-btns-container.appointments button{
    padding: 15%;
    background: #fff;
}

.toggle-container div {
    padding-inline-end: 1rem;
    padding-top: 1rem;
}

.toggle-btn {
    background: #fff;
    border-radius: var(--lg-radius) !important;
    box-shadow: 0px 0px 5px 1px #ddd;
    min-height: 55px !important;
    display: inline-block !important;
    line-height: 1.5 !important;
    text-align: start !important;
    align-items: start !important;
}

.toggle-btn.Mui-selected {
    color: #fff !important;
    background: var(--secondary-color) !important;
}

.toggle-btn>.MuiTab-iconWrapper {
    margin-bottom: -8px !important;
    margin-left: 5px;
    margin-right: 5px;
    padding-top: 5px;
}

.switch-container {
    position: relative;
    width: max-content;
    height: 30px;
    margin: auto;
    border-radius: 25px;
    background: #fff;
    cursor: pointer;
    border: 1px solid #ececec;
}

.switch-btn {
    width: fit-content;
    display: inline-block;
    text-align: center;
    color: #000;
    line-height: 1;
    z-index: 2;
    position: relative;
    padding: 0 25px !important;
}

.switch-btn.Mui-selected {
    color: var(--primary-color) !important;
    background: #fff;
    border-radius: 25px;
    transition: all 500ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border: 2px solid var(--primary-color);
    font-weight: bold !important;
}

.MuiTabs-indicator {
    display: none;
}