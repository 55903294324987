.branch-item{
    background: #fff;
    border: 1px solid #d9d9d9;
    border-radius: var(--sm-radius);
    padding: 15px 0;
    align-items: center;
}

.branch-item > div:not(:nth-of-type(2),:last-of-type){
    font-size: 20px;
    overflow-wrap: anywhere;
}
.branch-item-no, .branch-item-name {
    font-weight: bold;
}

.branch-item-no {
    color: #ccc;
}

.branch-item > div:nth-of-type(2) img{
    max-width: 100%;
    height: 42px;
    width: 42px;
    border-radius: 50%;
}

.branch-item > *{ text-transform: capitalize; }


