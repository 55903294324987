li.MuiBreadcrumbs-separator{ font-size: 1.8rem; }

.branch-head{
    justify-content: space-between;
}


.branch-head .Search-branch fieldset{
    border: none;
    outline: none;
    box-shadow: none;
} 

.branch-head .MuiBreadcrumbs-root a{ text-decoration: none !important; }

.Search-branch > div{
    padding: 10px 15px 8px !important;
    border-radius: 10px !important;
    box-shadow: inset 0 1px 4px 0 rgba(0, 0, 0, 0.1) !important;
    background-color: #fff !important;
}

.Search-branch > div > input{
    padding: 0px !important;
}

.branch-head .switch-btn{
    margin-bottom: 0px !important;
    display: flex !important;
    align-items: center;
}

#filter-menu ul.MuiList-root.MuiMenu-list{ max-height: 771px; overflow: auto; }
div[id^='react-select']{ position: static !important; }


#filter-menu .searchable-select{ box-shadow: none !important; }

#filter-menu .searchable-select div[class$="placeholder"]{
    color: #848383 !important;
}

#filter-menu .searchable-select div[class$="control"]{
    border: 1px solid #848383 !important;
}

#filter-menu span[class$='indicatorSeparator']{
    display: none !important;
}
#filter-menu div[class$='indicatorContainer'] svg{
    color: #848383 !important;
}