/* Select Language */
.select-lang-wrap {
    align-items: center;
    gap: 1rem;
}

.select-lang-wrap {
    width: 100%;
    flex-wrap: wrap;
    gap: 5px 20px;
}

.select-lang-wrap .MuiFormControl-root > .MuiInputBase-root.MuiInputBase-formControl{
    border-radius: 10px;
}
.select-lang-wrap .MuiFormControl-root > label.MuiFormLabel-root {
    display: block !important;
    background-color: #fff !important;    
}

.select-lang-wrap  > .MuiFormControl-root{
    width: 202px;
}
.select-lang-wrap .select {
    cursor: pointer;
}
.select-lang-wrap .select .MuiSelect-select.MuiInputBase-input {
    width: 100%;
    padding-right: 0px;
    border-top: 1px solid #f6f6f6;
    border: none;
    font-size: 16px !important;
    font-weight: bold !important;
    color: #333132 !important;
}
.select-lang-wrap .select svg {
    color: #333132 !important;
    font-size: 20px !important;
}

.select-lang-wrap .select fieldset {
    border: none;
    box-shadow: none;
}

.select-lang-wrap .select input::placeholder {
    color: #333132;
}



.select-lang-wrap  > p {
    font-size: 16px !important;
    color: #333132 !important;
}
.select-lang-wrap  > p:first-of-type{ font-weight: bold !important; }
.select-lang-wrap  > p:last-of-type{ font-weight: normal !important; }