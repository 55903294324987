.job-offer-head > .MuiGrid-root.MuiGrid-container.MuiGrid-item:first-of-type{ max-width: 100% !important; }
.job-offer-head li.MuiBreadcrumbs-separator{ 
    font-size: 20px;
    color: #bebebe;
}
.job-offer-head .MuiBreadcrumbs-root a,
.job-offer-head .MuiBreadcrumbs-root p{
    text-decoration: none !important; 
    font-size: 18px;
    color: #bebebe;
}

.Search-job{
    width: 330px !important;    
}

.job-offer-head .Search-job fieldset{
    border: none;
    outline: none;
    box-shadow: none;
} 
.Search-job > div{
    padding: 9px 15px 8px !important;    
    box-shadow: inset 0 1px 4px 0 rgba(0, 0, 0, 0.1) !important;
    background-color: #fff !important;
    color: #333132;
    border-radius: 10px;
    border-top: solid 1px #ececec;
}

.filter-menu-wrap .filter-menu-button{
    padding: 0px !important;
}

.filter-menu-wrap .searchable-select.filter-menu-button div[class$="control"]{
    border: none !important;
    border-top: 1px solid #ececec;
    box-shadow: inset 0 1px 4px 0 #0000001a!important;
    max-width: 13rem;
    width: 13rem;
}



.filter-menu-wrap .searchable-select.filter-menu-button div[class$="control"] div[class$="placeholder"],
.filter-menu-wrap .searchable-select.filter-menu-button div[class$="control"] div[class$="singleValue"]{
    font-size: 16px!important;
    font-weight: 700;
    text-transform: capitalize;
    white-space: pre-wrap;    
}
.filter-menu-wrap .searchable-select.filter-menu-button div[class$="control"] div[class$="placeholder"],
.filter-menu-wrap .searchable-select.filter-menu-button div[class$="control"] div[class$="singleValue"],
.filter-menu-wrap .searchable-select.filter-menu-button div[class$="control"] div[class$='indicatorContainer'] svg{
    color: #333132 !important;
}

.filter-menu-wrap .searchable-select.filter-menu-button div[class$="control"] span[class$='indicatorSeparator']{
    display: none !important;
}

.filter-menu-wrap .filter-menu-button > svg{ color: inherit !important; }

.filter-menu-wrap .searchable-select.filter-menu-button div[class$="menu"]{
    max-height: 40rem!important;
    background-color: #fff !important;
}

.filter-menu-wrap .searchable-select.filter-menu-button div[class$="menu"] div{
    font-weight: 500 !important;
    color: #818181 !important;
    background-color: #fff !important;
    text-transform: capitalize !important;
    border-radius: 0px !important;
}
.filter-menu-wrap .searchable-select.filter-menu-button div[class$="menu"] div:hover{
    background-color: #0000000a !important;
}

.Search-job > div > input{
    padding: 0px !important;
}

.job-offer-head .switch-btn{
    margin-bottom: 0px !important;
    display: flex !important;
    align-items: center;
    font-size: 16px;
    font-weight: 600;
    color: #333132;
}

div[id^='demo-positioned-menuAll'] ul.MuiMenu-list{
    width: 13rem !important;
    max-height: 40rem !important;
}

div[id^='demo-positioned-menuAll'] .MuiPaper-root.MuiPaper-elevation.MuiPopover-paper{
    top: 11.2rem !important;
}


#filter-menu ul.MuiList-root.MuiMenu-list{ max-height: 701px; overflow: auto; }
div[id^='react-select']{ position: static !important; }

#filter-menu .reservation-search input.MuiInputBase-input{ padding: 11px 0px !important; }
#filter-menu .reservation-search input.MuiInputBase-input.MuiOutlinedInput-input::placeholder{
    opacity: 1;
    color: #848383 !important;
}
#filter-menu .reservation-search fieldset,
#filter-menu .basic-date-picker fieldset{ 
    border: none !important; 
}



#filter-menu .searchable-select{ box-shadow: none !important; }
#filter-menu .searchable-select div[class$="placeholder"]{ color: #848383 !important; }
#filter-menu .searchable-select div[class$="control"],
#filter-menu .reservation-search .MuiInputBase-root.MuiInputBase-formControl{
    border: 1px solid #bebebe !important;
    box-shadow: none !important;
    border-radius: 10px;
    padding: 0px 3.5px;
}
#filter-menu .searchable-select div{ text-transform: capitalize; }

#filter-menu span[class$='indicatorSeparator']{
    display: none !important;
}
#filter-menu div[class$='indicatorContainer'] svg{
    color: #848383 !important;
    width: 1rem;
}


#filter-menu .reservation-search input[placeholder="From"]::-webkit-outer-spin-button,
#filter-menu .reservation-search input[placeholder="From"]::-webkit-inner-spin-button,
#filter-menu .reservation-search input[placeholder="To"]::-webkit-outer-spin-button,
#filter-menu .reservation-search input[placeholder="To"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}