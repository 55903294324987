.table-header.job-offer-header > div{
    font-size: 20px;
    font-weight: 500;
    color: #818181;
}

.job-offer-verified, .job-offer-not-verified{
    padding: 7px 22px 8px;
    border-radius: 10px;
    color: white;
    width: 33px;
    max-width: 33px !important;
    text-align: center;
}

.job-offer-verified{
    background-color: #62c08b;
}
.job-offer-not-verified{
    background-color: #cc4d49;
}


.job-offer-item{
    background: #fff;
    border: 1px solid #d9d9d9;
    padding: 15px 0;
    align-items: center;
}


.job-offer-item-no, .job-offer-item-name {
    font-weight: bold;
}

.job-offer-item-no {
    color: #ccc !important;
}

.job-offer-item > *{ 
    text-transform: capitalize; 
    font-size: 20px;
    color: #333132;
    word-break: break-word;
}
.job-offer-item button{
    font-size: 16px;
    background-color: transparent;
    padding: 0;
    margin-left: 1rem;
    border: none;
    cursor: pointer;
}
.job-offer-item .applicant-wrap{
    color: #272726;
}
.job-offer-item .applicant-wrap p{ font-size: 20px; text-transform: lowercase !important; }
.job-offer-item .applicant-wrap span{ font-size: 16px; }

@media (min-width: 900.1px) and (max-width: 1200px){
    .job-offer-header, .job-offer-item{
        gap: 1rem;
    }
}

@media (max-width: 900px){
    .job-offer-header, .job-offer-item{
        gap: 0.5rem;
    }
}