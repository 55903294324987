.flow-form {
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.1);
  background: #fff;
  border-radius: 10px;
  padding: 20px;
  width: calc(50% - 48px);
}

.flow-form .MuiFormControl-root.MuiTextField-root:last-of-type,
.flow-form .MuiBox-root:nth-of-type(3) > div > div {
  width: 100% !important;
}

/* select */
.flow-form .MuiSelect-select {
  padding-right: 20px !important;
  padding-left: 10px !important;
  border-radius: 10px;
  box-shadow: inset 0 1px 5px 0 rgba(0, 0, 0, 0.2);
}

.flow-form svg.MuiSelect-icon {
  right: 4px !important;
  color: var(--primary-color) !important;
}

.flow-form #name,
.flow-form #role_id,
.flow-form #email,
.flow-form #phone_number,
.flow-form #password,
.flow-form #password_confirmation,
.flow-form input {
  padding-inline: 14px !important;
  border-radius: 10px;
  box-shadow: inset 0 1px 5px 0 rgba(0, 0, 0, 0.2);
  font-size: 18px;
}
.flow-form input,
.flow-form .MuiInputBase-root.MuiInputBase-formControl.MuiInputBase-multiline {
  color: #333132;
  /* text-transform: capitalize; */
}

.flow-form .MuiFormLabel-root {
  color: #bebebe !important;
  background-color: #fff;
}
.flow-form
  label.MuiFormLabel-root.MuiInputLabel-formControl.MuiInputLabel-shrink {
  display: none !important;
}

.flow-form fieldset {
  box-shadow: none !important;
  border: none !important;
}

.flow-form #phone_number::-webkit-outer-spin-button,
.flow-form #phone_number::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.flow-form .MuiOutlinedInput-root {
  border-radius: 10px;
    box-shadow: inset 0 1px 5px 0 rgba(0, 0, 0, 0.2);
    
}

.flow-form .MuiOutlinedInput-root fieldset {
  border: none;
  outline: none;
  box-shadow: none !important;
}

.flow-form .activity-img-container {
  max-width: 100% !important;
}

/* form buttons(submit , cancel) */
.flow-form-buttons button {
  height: 60px !important;
  font-size: 18px !important;
  font-weight: 500 !important;
  border-radius: 25px !important;
  line-height: 2 !important;
  text-transform: capitalize !important;
  box-shadow: none !important;
}
.flow-form-buttons button:nth-of-type(1) {
  width: 110px;
  padding: 16px 0px 14px !important;
  border: solid 1px #ececec !important;
  color: #333132 !important;
  background-color: rgba(250, 161, 0, 0) !important;
}
.flow-form-buttons button:nth-of-type(2) {
  min-width: 110px;
  padding: 16px 40px 14px !important;
  color: #fff !important;
}
.flow-form-buttons button:nth-of-type(2):hover {
  background-color: #f6b119 !important;
  box-shadow: none !important;
}
.MuiGrid-root > .MuiGrid-root > .MuiBox-root > .MuiStack-root,
div.MuiStack-root:has(> .flow-form-buttons) {
  /* border-bottom: 1px solid rgb(217, 217, 217) !important; */
  margin-bottom: 20px !important;
}

/* Select Language */
.select-lang-wrap {
  align-items: center;
  gap: 1rem;
}
.select-lang-wrap > .MuiFormControl-root.MuiFormControl-fullWidth {
  width: auto;
}
.select-lang-wrap .select {
  cursor: pointer;
}
.select-lang-wrap .select .MuiSelect-select.MuiInputBase-input {
  width: 100%;
  padding-right: 0px;
  border-top: 1px solid #f6f6f6;
  border: none;
  font-size: 16px !important;
  font-weight: bold !important;
  color: #333132 !important;
}
.select-lang-wrap .select svg {
  color: #333132 !important;
  font-size: 20px !important;
}

.select-lang-wrap .select fieldset {
  border: none;
  box-shadow: none;
}

.select-lang-wrap .select input::placeholder {
  color: #333132;
}

.select-lang-wrap > p {
  font-size: 16px !important;
  font-weight: bold !important;
  color: #333132 !important;
}

.select-lang-wrap .MuiFormControl-root > label.MuiFormLabel-root {
  display: none;
}
.select-lang-wrap
  > .MuiGrid-container
  > .MuiGrid-root.MuiGrid-container.MuiGrid-item
  > .MuiGrid-root.MuiGrid-item:nth-of-type(2) {
  margin: 0px !important;
  max-width: 15rem !important;
}
.select-lang-wrap
  > .MuiGrid-container
  > .MuiGrid-root.MuiGrid-container.MuiGrid-item
  > .MuiGrid-root.MuiGrid-item:nth-of-type(2)
  > .MuiGrid-item {
  margin: 0;
  width: 100%;
}
.select-lang-wrap
  > .MuiGrid-container
  > .MuiGrid-root.MuiGrid-container.MuiGrid-item
  > .MuiGrid-root.MuiGrid-item:not(.MuiGrid-container) {
  width: auto;
  max-width: max-content;
  margin-right: 1rem !important;
}

/* upload image */
.flow-add-img-container {
  border: 1px solid var(--primary-color);
  border-radius: var(--lg-radius);
  height: 140px;
  overflow: hidden;
  text-align: center;
  width: 140px;
}
.flow-add-img-container img {
  height: 100%;
  margin: auto;
  max-width: 100%;
  width: auto;
}


.flow-form .search-select{ position: relative !important; }
.flow-form .search-select div[id$='placeholder'] { color: #bbbbbb !important; }
.flow-form .search-select > div[class$='control']{ 
  border: none !important;
  padding-inline: 14px !important;
  border-radius: 10px !important;
  box-shadow: inset 0 1px 5px 0 rgb(0 0 0 / 30%) !important;
  font-size: 18px !important;
  min-height: 58.88px !important;
  cursor: pointer !important;
}
.flow-form .search-select span[class$="indicatorSeparator"]{ display: none !important; }

.search-select {
  height: -webkit-fill-available !important;
}
.search-select > div[class$='control'] > div[class$='ValueContainer'] > div[class$='singleValue']{
  white-space: inherit !important;
}