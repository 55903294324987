.customer-table {
  box-shadow: none !important;
  min-height: 82vh;
  background-color: #fcfcfc !important;
}
.customer-table > div.MuiStack-root p {
  font-size: 30px;
  font-weight: bold;
  text-align: left;
  color: #333132;
  margin-right: 2rem;
}

.customer-table .search-input {
  padding: 9px 15px 8px !important;
  background-color: #fff !important;
  color: #333132;
  border: solid 1px #ececec;
  border-radius: 10px;
}
.customer-table .search-input > .MuiInputBase-root {
  padding: 0px !important;
  border: none !important;
}
.customer-table .search-input fieldset {
  border: none;
  outline: none;
  box-shadow: none;
}

.customer-table
  > div.MuiStack-root
  > div.MuiStack-root
  > div.MuiStack-root
  .MuiInputBase-root {
  margin-right: 0px !important;
}
.customer-table
  > div.MuiStack-root
  > div.MuiStack-root
  > div.MuiStack-root
  input {
  padding: 0px !important;
}

#filter-menu ul.MuiList-root.MuiMenu-list {
  max-height: 404px;
  overflow: auto;
  width: 270px !important;
  padding-bottom: 0px !important;
}
.filter-menu-item-head {
  font-size: 16px !important;
  font-weight: 500 !important;
  color: #333132 !important;
  text-transform: capitalize !important;
}

.customer-table hr.MuiDivider-root {
  border-color: #d9d9d9 !important;
}

.customer-table table thead th > div {
  font-size: 20px;
  font-weight: 500;
  color: #818181;
}
.customer-table table thead th:first-of-type > div {
  justify-content: center;
}

table {
  border-collapse: separate !important;
  border-spacing: 0px 20px !important;
}

tr td:first-child {
  border-left: 0.5px solid #d9d9d9 !important;
  border-radius: 10px;
}
tr td:last-child {
  border-right: 0.5px solid #d9d9d9 !important;
  border-radius: 10px;
}

.customer-table table thead tr > * {
  padding: 5px 5px 0px;
}
.customer-table table thead tr th {
  border-bottom: none !important;
}
.customer-table table thead tr th:first-of-type {
  width: 3rem;
  text-align: center;
  text-transform: uppercase;
}

.customer-table table tbody tr {
  background: #fff !important;
  outline: 1px solid #d9d9d9 !important;
  padding: 15px 0 !important;
  border-radius: 10px !important;
}

.customer-table table tbody tr td:first-of-type,
table tbody tr td:nth-of-type(2) {
  font-weight: bold;
}
.customer-table table tbody tr td:first-of-type {
  color: #ccc !important;
  text-align: center;
  width: 3rem;
}

.customer-table table thead tr p {
  font-size: 20px !important;
}
.customer-table table tbody tr > * {
  /* text-transform: capitalize;  */
  font-size: 20px;
  color: #333132;
  word-break: break-word;
  padding: 27px 5px;
}
.customer-table table tbody tr > *:not(td:nth-of-type(3)) {
  text-transform: capitalize;
}
.customer-table table tbody tr button {
  font-size: 16px;
  background-color: transparent;
  cursor: pointer;
  border: 1px solid #ededed;
  border-radius: 10px;
  padding: 4px;
  width: 34px !important;
  max-width: 34px !important;
  display: block;
  height: 34px;
  margin-left: 0px !important;
  margin-right: auto !important;
}

.customer-table table tbody tr ul {
  margin-top: 11px;
}
.customer-table table tbody tr ul li {
  white-space: pre-wrap;
}

.customer-table .customer-verified,
.customer-not-verified {
  padding: 7px 22px 8px;
  border-radius: 10px;
  color: white;
  width: 33px;
  max-width: 33px !important;
  text-align: center;
  margin: auto;
}

.customer-table .customer-verified {
  background-color: #62c08b;
}
.customer-table .customer-not-verified {
  background-color: #cc4d49;
}
