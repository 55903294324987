#filter-menu .searchable-select{ box-shadow: none !important; }

#filter-menu .searchable-select div[class$="placeholder"],
#filter-menu .reservation-search input.MuiInputBase-input.MuiOutlinedInput-input::placeholder{
    color: #848383 !important;
}

#filter-menu .reservation-search fieldset {
    box-shadow: none !important;
    border-color: #bebebe !important;
}

#filter-menu .reservation-search .MuiInputBase-root.MuiInputBase-formControl{
    height: 50px;
}

#filter-menu .searchable-select div[class$="control"]{
    border: 1px solid #bebebe !important;
}